

.test-l {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-left: 0px;
  margin-top: 10px;
  border-radius: 10px;
  margin-right: 20px;
}

.test-r {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 0px
}

.table-view {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.navigation {
  margin: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

.navigation-dropdown {
  display: flex;
  justify-content: flex-end;
}

.navigation .btn {
  border: none;
  box-shadow: none;
}

.charts .btn {
  border: none;
  box-shadow: none;
}

.frames .equity-curves-selector .btn {
  border: none;
  box-shadow: none;
}

.charts {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
}

.frames {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.equity-curves-selector {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.download-btn {
  margin: 5px;
}

.download-btn-icon {
  color: #8884d8;
}

.dropdown-item:active {
  background-color: black !important;
  color: white !important;
}

.kpi {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
