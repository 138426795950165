.sign-in {
    margin-left: 5px;
}

.sign-in-button {
    margin-right: 10px
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #DB4437;
    /* background-color: #8884d8; */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.avatar-toggle .dropdown-toggle::after {
    display: none;
}

.avatar-toggle:hover {
    border-color: white;
}

.separator {
    border-right: 1px solid #000;
    height: 100%;
  }

.free-trial-container {
    display: flex;
    align-items: center;
}

.trial-text {
    color: white;
    margin-right: 10px;
}

.separator {
  border-right: 1px solid white;
  height: 100%;
  margin-right: 10px;
}