.add-bot {
  height: 100px;
  display: flex;
  align-items: center;
}

.saved-bots-list {
  background-color: #f8f9fa;
  margin-bottom: 5px;
  border-radius: 10px;
  border-width: 0px;
}
.saved-bots {
  background-color: #f8f9fa;
  margin-bottom: 5px;
  border-radius: 10px;
  border-width: 0px;
}

.saved-bots:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.active-item {
  background-color: #343a40 !important;
  margin-bottom: 5px !important;
  border-color: #343a40 !important;
}


.ai-bot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ai-bot-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.share {
  padding-right: 5px;
}

.share i:hover {
  color:  #8884d8;
}

.rename {
  padding: 5px;
}

.rename i:hover {
  color:  #8884d8;
}

.delete i:hover {
  color: #FF5C5C;
}

.ai-badge {
  padding: 0.25em 0.4em;
  font-size: 75%;
  text-align: center;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #0dcaf0;
  margin-right: 3px;
  font-family: 'Avenir Next', sans-serif;
  font-style: italic;
  font-weight: 700;

}

.align-ai-bot {
  display: flex;
  align-items: center;
  width: 0;
  flex-grow: 1;
}

.bot-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.arrow-rotate {
  transform: rotate(-180deg);
  transition: transform 0.3s ease;
}

.arrow-rotate-back {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.bot-category-name {
  font-size: 0.8em;
  font-weight: bold;
}

.chevron-style {
  margin-bottom: 2px;
}

.cancel i:hover {
  color: #8884d8;
}

.confirm {
  padding: 5px;
}

.confirm i:hover {
  color: #8884d8;
}

.rename-form-control {
  height: 100%;
}

.confirm-cancel {
  display: flex;
  align-items: center;
}