.logo-font {
    font-family: 'Avenir Next', sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.ai-font {
    width: 5px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: white;
    font-size: 24px;
    font-family: 'Avenir Next', sans-serif;
    font-style: italic;
    font-weight: 700;
    background-color: #0dcaf0;
    /* background-color: #8884d8; */
    margin-left: 3px;
}

.text-bold {
    font-weight: bold;
  }
  

.sign-in {
    margin-left: 5px;
}

.sign-in-button {
    margin-right: 10px
}

.responsive {
    display: flex;
    flex-wrap: wrap;
}
