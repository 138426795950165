.table-header {
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    z-index: 1;
}

.table-header-element {
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout-multi-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-features {
    overflow-x: auto;
}

.draw-down-rel {
    /* color: #FF5C5C; */
    color: #8884d8;
    margin-left: 10px;
}

.winner {
    /* color: #82ca9d; */
    color: #abb4f7;
    margin-left: 10px;
}

.loser {
    /* color: #FF5C5C; */
    color: #605aa0;
    margin-left: 10px;
}

.timeout {
    color: #808080;
    margin-left: 10px;
}

.no-entry {
    color: #8A8AFF;
    margin-left: 10px;
}

.highlighted-row {
    background-color: #12121212;
}

.icon-padding {
    margin-right: 5px;
}


.table-format {
    text-align: center;
}

.arrow-rotate {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
}

.arrow-rotate-back {
    transform: rotate(-180deg);
    transition: transform 0.3s ease;
}

.table {
    --bs-table-bg: unset;
}