.getting-started {
    display: flex;
    justify-content: center;
}

.getting-started-button {
    display: flex;
    justify-content: center;
}

.getting-started-timeline {
    display: flex;
    justify-content: center;
    margin: 10px;
    border: none;
    box-shadow: none;

}

.getting-started-ad {
    padding: 20px;
    margin: 10px;
    
}