
.footer {
    padding: 20px 0;
  }

  .footer-line {
    border-bottom: 1px solid #ddd;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
  
  .footer .row {
    display: flex;
    align-items: center;
  }
  
  .footer .col-md-6 {
    display: flex;
    justify-content: center;
  }
  
  .footer i {
    font-size: 10px;
    color: #8c8c8c;
    margin-right: 5px;
  }
  
  .footer .about-link {
    color: #8c8c8c;
    text-decoration: none;
  }
  
  .footer .about-link:hover {
    text-decoration: underline;
  }
  
  .footer .about-link:focus {
    outline: none;
  }
  
  .footer .about-link:active {
    color: #8c8c8c;
  }
  
  .footer .copyright {
    font-size: 12px;
    font-weight: 500;
    color: #8c8c8c;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  