.custom-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .custom-tooltip .label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .custom-tooltip .intro {
    font-style: italic;
    margin-bottom: 5px;
  }
  
  .custom-tooltip .desc {
    color: #888;
  }
  