.input-form-control {
    display: flex;
    justify-content: space-between;
}

.carousel {
    height: 500px
}

.form-check-input:checked {
    background-color: #212529;
    border-color: #212529;
}


.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid black;
    margin-right: 20px;
}

.bg-dark {
    background-color: #343a40;
}

.ai-badge {
    padding: 0.25em 0.4em;
    font-size: 75%;
    text-align: center;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #0dcaf0;
    font-family: 'Avenir Next', sans-serif;
    font-style: italic;
    font-weight: 700;
}

.ai-optimization {
    display: flex;
    justify-content: center;
}


.ai-intro {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.ai-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    margin-bottom: 20px;
}

.brand-name {
    font-weight: 700;
    font-family: 'Avenir Next', sans-serif;
}

.start-backtesting {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.input-form-progress {
    display: flex;
    justify-content: center;
}

#waitingForResponse .modal-content {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
}