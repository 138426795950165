.card {
    border: none;
    box-shadow: none;
  }

  .card-body {
    background-color: white;
}

.card-header {
    background-color: white;
}

.distance-icon-kpi {
  margin-right: 5px;
}

.color-win {
  /* color: #82ca9d; */
  color: #8884d8;
}

.color-loss {
  /* color: #FF5C5C; */
  color: #8884d8;

}

.color-neutral {
  color: #8884d8;
}